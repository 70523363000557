import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminOrderService {
    private apiUrl = environment.digigoldbe;
    constructor(private http: HttpClient,) { }

    getAllOrderDetails() {
        const url = `${this.apiUrl}/transaction/all-with-details`;
        return this.http.get(url);
    }
 
}

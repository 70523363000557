import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private apiUrl = environment.digigoldbe;
  constructor(private http: HttpClient,) {}

  getAllTransactions(){
    const url = `${this.apiUrl}/transaction`
    return this.http.get(url)
  }

}

import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  FormArray,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ProductDetailModel } from '../../../models/admin/product-detail.model';
import { ProductImageModel } from '../../../models/admin/product-image.model';
import {
  CreateProductModel,
  ProductModel,
} from '../../../models/admin/product.model';
import { AdminProductService } from '../../../services/admin/products/products.service';
import { CommonService } from '../../../services/common/common.service';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [
    TableModule,
    CardModule,
    CommonModule,
    BreadcrumbModule,
    ButtonModule,
    FormsModule,
    DialogModule,
    InputTextModule,
    RadioButtonModule,
    ToastModule,
    ReactiveFormsModule,
    DropdownModule,
    InputSwitchModule,
    TagModule,
    ImageModule,
    FieldsetModule,
    FileUploadModule,
    OverlayPanelModule,
    NgxPaginationModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss',
})
export class ProductsComponent {
  @ViewChild('dt1') table!: Table;
  products: ProductModel[] = [];
  loading: boolean = true;
  displayAddProductDialog: boolean = false;
  errorMessage: string | null = null;
  isEditing: boolean = false;
  productIcon: any;
  displayImageDialog: boolean = false;
  selectedImages: any[] = [];
  productTypeOptions: any;
  materialTypeOptions: any;
  productSeriesOptions: any;
  displayMultipleProductImageDialog: boolean = false;
  currentProductImages: ProductImageModel[] = [];
  currentProduct: string = '';
  currentProductId: number = 0;
  selectedProductImageFiles: File[] = [];
  isUploading: boolean = false;
  attachments: File[] = [];
  filesData: any[] = [];
  images :any[]=[]
  selectedProduct: any;
  showFiles: boolean = false;
  p: number = 1;

  // Forms
  addProductForm!: FormGroup;
  updateProductForm!: FormGroup;
  currProductDetailForm!: FormGroup;

  // Boolean variables
  isCurrProductDetailDialogVisible = false;
  allFieldsRequiredError: boolean = false;

  // State variables
  currProductDeatilId: number | null = null;

  addProductError: string = '';

  isUpdate: boolean = false;
  currProductId: any = null;

  // Dialog header
  dialogHeader: string = 'Add Product';

  ngOnInit(): void {
    this.materialTypeOptions = [];
    this.productTypeOptions = [];
    this.productSeriesOptions = [];

    this.currProductDetailForm = this.fb.group({
      details: this.fb.array([])
    });

    ['weight', 'purity', 'price', 'quantity'].forEach(control => {
      this.addProductForm.get(control)?.valueChanges.subscribe(() => {
        this.addProductForm.get(control)?.updateValueAndValidity();
      });
    });
  }

  constructor(
    private fb: FormBuilder,
    private adminProductService: AdminProductService,
    private messageService: MessageService,
    private commonService: CommonService
  ) {

    this.addProductForm = this.fb.group({
      productName: [null, Validators.required],
      materialType: [null, Validators.required],
      productType: [null, Validators.required],
      productCode: [null, Validators.required],
      productSeries: [null, Validators.required],
      weight: [null, [Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
      purity: [null, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,4})?$'), Validators.min(0)]],
      price: [null, [Validators.required, Validators.min(0), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
      makingCharge: [null, [Validators.min(0), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
      discountPercent: [null, [Validators.min(0), Validators.max(100), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
      discountAmount: [null, [Validators.min(0), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
      quantity: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      isFeatured: [true],
      isDigital: [false]
    });

    this.addProductForm.controls['isDigital'].disable();

    this.addProductForm.get('discountPercent')?.valueChanges.subscribe(value => {
      if (value) {
        this.addProductForm.get('discountAmount')?.disable();
      } else {
        this.addProductForm.get('discountAmount')?.enable();
      }
    });

    this.addProductForm.get('discountAmount')?.valueChanges.subscribe(value => {
      if (value) {
        this.addProductForm.get('discountPercent')?.disable();
      } else {
        this.addProductForm.get('discountPercent')?.enable();
      }
    });

    this.getAllProducts();
    this.getMaterialTypeOptions();
    this.getProductShapeOptions();
    this.getProductSeriesOptions();
  }

  refreshTable() {
    this.getAllProducts();
  }

  clearFilters() {
    this.table.clear();
  }

  showImg(product: any) {
    this.images=product.images
    this.showFiles = true;
    console.log(product);
  }

  // Dropdown related methods
  getMaterialTypeOptions() {
    this.adminProductService.getMaterialTypeOptions().subscribe(
      (res: any) => {
        if (res.statusCode === 200) {
          this.materialTypeOptions = res.data.map((item: any) => ({
            label: item?.itemCode,
            value: item?.itemCode
          }));
        }
      },
      (error: any) => {
        console.error('Error fetching products:', error);
      }
    );
  }

  getProductShapeOptions() {
    this.adminProductService.getProductShapeOptions().subscribe(
      (res: any) => {
        if (res.statusCode === 200) {
          this.productTypeOptions = res.data.map((item: any) => ({
            label: item?.itemCode,
            value: item?.itemCode
          }));
        }
      },
      (error: any) => {
        console.error('Error fetching products:', error);
      }
    );
  }

  getProductSeriesOptions() {
    this.adminProductService.getProductSeriesOptions().subscribe(
      (res: any) => {
        if (res.statusCode === 200) {
          this.productSeriesOptions = res.data.map((item: any) => ({
            label: item?.itemCode,
            value: item?.itemCode
          }));
        }
      },
      (error: any) => {
        console.error('Error fetching products:', error);
      }
    );
  }

  handleFileInput(event: any, product: ProductModel) {
    console.log(product);
    this.selectedProduct = product;
    this.attachments = event.files;

    console.log(this.attachments);
    if (this.attachments.length > 0) {
      this.adminProductService
        .uploadProductImages(product.id, product.name, this.attachments)
        .subscribe({
          next: (response) => {
            console.log('response', response);

            this.isUploading = false;
            const productIndex = this.products.findIndex(
              (product) => product.id === this.selectedProduct.id
            );
            console.log(this.selectedProduct, productIndex);
            const images = response.map((data:any)=>data.data)
            console.log('images',images)
            if (productIndex > -1) {
              this.products[productIndex].images = [
                ...this.products[productIndex].images,
                ...images,
              ];
            }
            this.filesData = this.products[productIndex].images;
            console.log('ff',this.filesData)
            this.commonService.showMessage(
              'success',
              'Image uploaded successfully'
            );
            this.attachments=[]
          },
          error: (error) => {
            console.error('Error uploading images:', error);
            this.isUploading = false;
          },
        });
    }
  }
  downloadFile(file: any) {
    console.log(file);
  }

  deleteFile(file: any) {
    console.log(file);
    const imageId = Number(file.id);
    this.adminProductService.deleteProductImageById(imageId).subscribe(
      (response) => {
        const productIndex = this.products.findIndex(
          (product) => product.id === this.selectedProduct.id
        );
        console.log(this.selectedProduct, productIndex);
        if (productIndex > -1) {
          this.products[productIndex].images = this.products[
            productIndex
          ].images.filter((image: any) => image.id !== imageId.toString());
          this.filesData = this.selectedProduct.images;
          this.images=this.selectedProduct.images;
        }
        this.commonService.showMessage('success', 'Image deleted successfully');
        console.log('Image deleted successfully', response);
      },
      (error) => {
        this.commonService.showMessage('error', 'Error deleting image');
        console.error('Error deleting image:', error);
      }
    );
  }
  showFileDetails(product: any) {
    this.selectedProduct = product;
    this.filesData = product.images;
    console.log(this.filesData);
  }
  getAllProducts() {
    this.loading = true;
    this.adminProductService.getAllProducts().subscribe(
      (res: any) => {
        if (res.statusCode === 200) {
          this.products = res.data.map((item: any) => ({
            id: item?.id,
            name: item?.name,
            materialType: item?.materialType,
            productType: item?.productType,
            weight: item?.weight,
            purity: item?.purity,
            price: item?.price,
            makingCharge: item?.makingCharge,
            discountPercent: item?.discountPercent,
            discountAmount: item?.discountAmount,
            inventory: item?.inventory,
            isFeatured: item?.isFeatured,
            isDigital: item?.isDigital,
            isActive: item?.isActive,
            productCode: item?.productCode,
            productSeries: item?.productSeries,
            images: item?.images,
            imageUrl:
              item?.images && item.images.length > 0
                ? item.images[0].url
                : null,
          }));
        }
        this.loading = false;
      },
      (error: any) => {
        console.error('Error fetching products:', error);
        this.loading = false;
      }
    );
  }

  showImageDialog(images: any[]): void {
    console.log(images);
    this.selectedImages = images || [];
    this.displayImageDialog = true;
  }

  showAddProductDialog() {
    this.displayAddProductDialog = true;
  }

  setProductStatus(id: number) {
    this.adminProductService.deleteProductById(id).subscribe(
      (response) => {
        this.getAllProducts();
        console.log('Product status has been updated:', response);
      },
      (error) => {
        console.error('Error while updating product status:', error);
      }
    );
  }

  getProductStatusLabel(status: boolean): string {
    switch (status) {
      case true:
        return 'Active';
      default:
        return 'Inactive';
    }
  }

  getProductStatusSeverity(status: boolean): 'success' | 'danger' {
    switch (status) {
      case true:
        return 'success';
      default:
        return 'danger';
    }
  }

  // Dialog functions


  showMultipleProductImageDialog(product: ProductModel) {
    this.displayMultipleProductImageDialog = true;
    this.currentProduct = product.name;
    this.currentProductId = product.id;
    this.currentProductImages = product.images.map((image: any) => ({
      id: image.id,
      productId: image.productId,
      name: product.name,
      url: image.url,
    }));
  }

  onFilesSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedProductImageFiles = Array.from(input.files);
    }
  }

  uploadImages() {
    if (this.selectedProductImageFiles.length > 0) {
      this.isUploading = true;
      this.adminProductService
        .uploadProductImages(
          this.currentProductId,
          this.currentProduct,
          this.selectedProductImageFiles
        )
        .subscribe({
          next: (response) => {
            const uploadedImageUrls = response.data;
            const newImageUrl = uploadedImageUrls[0];

            const productIndex = this.products.findIndex(
              (product) => product.id === this.currentProductId
            );
            if (productIndex !== -1) {
              this.products[productIndex].images = this.products[
                productIndex
              ].images.concat(
                uploadedImageUrls.map((url: any) => ({
                  id: this.currentProductId,
                  productId: this.currentProductId,
                  name: this.currentProduct,
                  url: url,
                }))
              );

              this.products[productIndex].imageurl = newImageUrl;

              this.currentProductImages = this.products[productIndex].images;
            }

            this.isUploading = false;
            this.selectedProductImageFiles = [];
            (
              document.querySelector('input[type=file]') as HTMLInputElement
            ).value = '';
          },
          error: (error) => {
            console.error('Error uploading images:', error);
            this.isUploading = false;
          },
        });
    }
  }

  // Product related functions
  addProduct() {
    if (this.addProductForm.valid) {
      let statusMsg = 'This Product has been added.';
      if (this.dialogHeader == 'Update Product') statusMsg = 'This Product has been updated.';
      this.allFieldsRequiredError = false;
      console.log(this.addProductForm.value);
      let newProduct = {
        'name': this.addProductForm.get('productName')?.value,
        'materialType': this.addProductForm.get('materialType')?.value,
        'productType': this.addProductForm.get('productType')?.value,
        'productCode': this.addProductForm.get('productCode')?.value,
        'productSeries': this.addProductForm.get('productSeries')?.value,
        'weight': this.addProductForm.get('weight')?.value,
        'purity': this.addProductForm.get('purity')?.value,
        'price': this.addProductForm.get('price')?.value,
        'makingCharge': this.addProductForm.get('makingCharge')?.value ?? null,
        'discountPercent': this.addProductForm.get('discountPercent')?.value ?? null,
        'discountAmount': this.addProductForm.get('discountAmount')?.value ?? null,
        'inventory': this.addProductForm.get('quantity')?.value,
        'isFeatured': this.addProductForm.get('isFeatured')?.value,
        'isDigital': this.addProductForm.get('isDigital')?.value
      }
      this.adminProductService.addProduct(newProduct).subscribe(
        (res) => {
          this.getAllProducts();
          this.messageService.add({ severity: 'success', summary: 'Success', detail: statusMsg });
          this.hideAddProductDialog();
        },
        error => {
          console.error('Error saving product:', error);
        }
      );
    } else {
      this.allFieldsRequiredError = true;
    }
  }

  updateProductById(productId: number) {
    this.dialogHeader = 'Update Product';
    this.isUpdate = true;
    this.currProductId = productId;
    this.displayAddProductDialog = true;
    let selectedProduct = this.products.filter(item => item.id == productId);
    this.addProductForm = this.fb.group({
      productName: [selectedProduct[0].name, Validators.required],
      materialType: [selectedProduct[0].materialType, Validators.required],
      productType: [selectedProduct[0].productType, Validators.required],
      productCode: [selectedProduct[0].productCode, Validators.required],
      productSeries: [selectedProduct[0].productSeries, Validators.required],
      weight: [selectedProduct[0].weight, [Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
      purity: [selectedProduct[0].purity, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,4})?$'), Validators.min(0)]],
      price: [selectedProduct[0].price, [Validators.required, Validators.min(0), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
      makingCharge: [selectedProduct[0].makingCharge, [Validators.min(0), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
      discountPercent: [selectedProduct[0].discountPercent, [Validators.min(0), Validators.max(100), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
      discountAmount: [selectedProduct[0].discountAmount, [Validators.min(0), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
      quantity: [selectedProduct[0]?.inventory, [Validators.required, Validators.pattern('^[0-9]*$')]],
      isFeatured: selectedProduct[0].isFeatured,
      isDigital: selectedProduct[0].isDigital
    });
    this.addProductForm.get('discountPercent')?.valueChanges.subscribe(value => {
      if (value) {
        this.addProductForm.get('discountAmount')?.disable({ emitEvent: false });
      } else {
        this.addProductForm.get('discountAmount')?.enable({ emitEvent: false });
      }
    });

    this.addProductForm.get('discountAmount')?.valueChanges.subscribe(value => {
      if (value) {
        this.addProductForm.get('discountPercent')?.disable({ emitEvent: false });
      } else {
        this.addProductForm.get('discountPercent')?.enable({ emitEvent: false });
      }
    });
  }

  updateCurrentProduct() {

    let currProduct = {
      'name': this.addProductForm.get('productName')?.value,
      'materialType': this.addProductForm.get('materialType')?.value,
      'productType': this.addProductForm.get('productType')?.value,
      'productCode': this.addProductForm.get('productCode')?.value,
      'productSeries': this.addProductForm.get('productSeries')?.value,
      'weight': Number(this.addProductForm.get('weight')?.value),
      'purity': Number(this.addProductForm.get('purity')?.value),
      'price': Number(this.addProductForm.get('price')?.value),
      'makingCharge': Number(this.addProductForm.get('makingCharge')?.value) ?? 0,
      'discountPercent': Number(this.addProductForm.get('discountPercent')?.value) ?? 0,
      'discountAmount': Number(this.addProductForm.get('discountAmount')?.value) ?? 0,
      'inventory': Number(this.addProductForm.get('quantity')?.value),
      'isFeatured': Number(this.addProductForm.get('isFeatured')?.value),
      'isDigital': Number(this.addProductForm.get('isDigital')?.value)
    }
    this.adminProductService.updateProductById(this.currProductId, currProduct).subscribe(
      response => {
        console.log('Product updated successfully:', response);
        this.getAllProducts();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Product details has been updated' });
        this.hideAddProductDialog();
        this.addProductForm = this.fb.group({
          productName: [null, Validators.required],
          materialType: [null, Validators.required],
          productType: [null, Validators.required],
          productCode: [null, Validators.required],
          productSeries: [null, Validators.required],
          weight: [null, [Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
          purity: [null, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,4})?$'), Validators.min(0)]],
          price: [null, [Validators.required, Validators.min(0), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
          makingCharge: [null, [Validators.min(0), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
          discountPercent: [null, [Validators.min(0), Validators.max(100), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
          discountAmount: [null, [Validators.min(0), Validators.pattern('^[0-9]+(\\.[0-9]+)?$')]],
          quantity: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
          isFeatured: [true],
          isDigital: [false]
        });
        this.addProductForm.controls['isDigital'].disable();

        this.addProductForm.get('discountPercent')?.valueChanges.subscribe(value => {
          if (value) {
            this.addProductForm.get('discountAmount')?.disable();
          } else {
            this.addProductForm.get('discountAmount')?.enable();
          }
        });

        this.addProductForm.get('discountAmount')?.valueChanges.subscribe(value => {
          if (value) {
            this.addProductForm.get('discountPercent')?.disable();
          } else {
            this.addProductForm.get('discountPercent')?.enable();
          }
        });
        this.dialogHeader = 'Add Product';
        this.isUpdate = false;
        this.getAllProducts();
      },
      error => {
        console.error('Error saving product:', error);
      }
    );
    console.log(currProduct);
  }

  hideAddProductDialog() {
    this.addProductForm.reset();
    this.addProductForm.get('isFeatured')?.setValue(true);
    this.addProductForm.get('isDigital')?.setValue(false);
    this.displayAddProductDialog = false;
    this.allFieldsRequiredError = false;
    this.dialogHeader = 'Add Product';
    this.isUpdate = false;
    this.addProductForm.controls['isDigital'].disable();

  }


  // Product details

  get details(): FormArray {
    return this.currProductDetailForm.get('details') as FormArray;
  }

  getCurrProductDetails(productId: number) {
    this.loading = true;
    this.currProductDeatilId = productId;
    this.adminProductService.getProductDetails().subscribe(
      (res: any) => {
        if (res.statusCode === 200) {
          this.details.clear();
          const filteredDetails = res.data.filter((item: any) => item?.productId === productId);
          filteredDetails.forEach((detail: any) => {
            this.details.push(this.createDetailFormGroup(detail.id, detail.productId, detail.key, detail.value));
          });
          this.loading = false;
          this.isCurrProductDetailDialogVisible = true;
          console.log('Product ID:', productId);
          console.log('Product Details:', this.currProductDetailForm.value);
        }
      },
      (error: any) => {
        this.loading = false;
        console.error('Error fetching product details:', error);
      }
    );
  }

  createDetailFormGroup(id: any, productId: any, key: string, value: string): FormGroup {
    return this.fb.group({
      id: [id],
      productId: [productId],
      key: [key, Validators.required],
      value: [value, Validators.required]
    });
  }

  addDetail(): void {
    this.details.push(this.createDetailFormGroup(null, null, '', ''));
  }

  deleteDetail(index: number): void {
    this.loading = true;
    const detail = this.details.at(index).value;
    this.adminProductService.deleteProductDetailById(detail.id).subscribe(
      (res: any) => {
        this.commonService.showMessage(
          'success',
          'This detail has been deleted'
        );
        this.details.removeAt(index);
        this.loading = false;
        console.log('Product detail deleted successfully:', res);
      },
      (error) => {
        this.loading = false;
        console.error('Error deleting product:', error);
      }
    );
    console.log(`Deleting detail at index ${index}:`, detail);
    console.log(`Detail ID: ${detail.id}, Product ID: ${detail.productId}`);
  }

  saveDetail(index: number | null): void {
    this.loading = true;
    const detail = index !== null ? this.details.at(index).value : null;

    const payload = {
      productId: this.currProductDeatilId,
      key: detail ? detail.key : '',
      value: detail ? detail.value : ''
    };

    if (detail && (detail.id !== null)) {
      this.adminProductService.updateDetail(detail.id, payload).subscribe(
        (res: any) => {
          this.commonService.showMessage('success', 'This detail has been updated');
          this.loading = false;
          console.log('Product detail updated successfully:', res);
        },
        (error: any) => {
          this.loading = false;
          console.error('Error updating product detail:', error);
        }
      );
    } else {
      this.adminProductService.createDetail(payload).subscribe(
        (res: any) => {
          this.commonService.showMessage('success', 'New detail has been saved');
          this.loading = false;
          console.log('New product detail saved successfully:', res);
        },
        (error) => {
          this.loading = false;
          console.error('Error saving new product detail:', error);
        }
      );
    }
  }

  triggerSaveDetail(index: number | null): void {
    if (index !== null) {
      this.saveDetail(index);
    } else {
      this.saveDetail(null);
    }
  }

}

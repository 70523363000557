<section class="container">
    <div class="card flex flex-column align-items-center">
        <p-card [style]="{ width: '100%', maxWidth: '1200px' }">
            <div class="confirm">
                <p style="font-size: 24px" class="font-bold mt-0 mb-0">Transactions</p>
                <p *ngIf="errorMessage" class="error-label block">
                    {{ errorMessage }}
                </p>
                <div class="flex justify-content-start align-items-center">
                    <p-button label="Download Excel" icon="pi pi-file-excel" class="p-button-success" (click)="exportToExcel()"></p-button>
                    <p-button label="Refresh" class="ml-2" (click)="refreshTable()" icon="pi pi-refresh"></p-button>
                    <p-button label="Clear" class="ml-2" (click)="clearFilters()" icon="pi pi-filter-slash"></p-button>
                </div>
            </div>
            <p-card [style]="{ 'margin-top': '1.5rem' }">
                <p-table #dt1 [value]="transactions" dataKey="id" [rows]="10" [rowsPerPageOptions]="[10, 25, 50]"
                    [paginator]="true"
                    [globalFilterFields]="['username', 'email', 'phoneNumber', 'date', 'type', 'status', 'amount']" [sortField]="'date'"
                    [sortOrder]="-1" [tableStyle]="{'min-width': '50rem'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="min-width:9rem">
                                <div class="flex align-items-center">
                                    Transaction ID
                                </div>
                            </th>
                            <th style="min-width:10rem">
                                <div class="flex align-items-center">
                                    Order ID
                                </div>
                            </th>
                            <th style="min-width:8rem">
                                <div class="flex align-items-center">
                                    Username
                                    <p-columnFilter type="text" field="username" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:8rem">
                                <div class="flex align-items-center">
                                    Email
                                    <p-columnFilter type="text" field="email" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:12rem">
                                <div class="flex align-items-center">
                                    Contact Number
                                    <p-columnFilter type="text" field="phoneNumber" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:10rem">
                                <div class="flex align-items-center">
                                    Date
                                    <p-columnFilter type="date" field="date" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:7rem">
                                <div class="flex align-items-center">
                                    Type
                                    <p-columnFilter type="text" field="type" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:8rem">
                                <div class="flex align-items-center">
                                    Status
                                    <p-columnFilter type="text" field="status" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:8rem">
                                <div class="flex align-items-center">
                                    Amount
                                    <p-columnFilter type="numeric" field="amount" display="menu" currency="INR" />
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-transaction>
                        <tr>
                            <td>{{ transaction.transactionId }}</td>
                            <td class="ellipsis" pTooltip="{{ transaction.orderId }}" tooltipPosition="top">{{transaction.orderId }}</td>
                            <td>{{ transaction.username }}</td>
                            <td>{{ transaction.email }}</td>
                            <td>{{ transaction.phoneNumber }}</td>
                            <td>{{ transaction.date | date: 'short' }}</td>
                            <td>{{ transaction.type }}</td>
                            <td>
                                <p-tag [value]="getStatusLabel(transaction.status)"
                                    [severity]="getStatusSeverity(transaction.status)" />
                            </td>
                            <td>{{ transaction.amount | currency: 'INR' }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="7">No transactions found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-card>
        </p-card>
    </div>
</section>
import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule, DatePipe } from '@angular/common';
import { CardModule } from 'primeng/card';
import { Table } from 'primeng/table';
import { ViewChild } from '@angular/core';
import { AdminOrderService } from '../../../services/admin/orders/orders.service';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    CommonModule,
    CardModule,
    TagModule,
    TooltipModule
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss'
})
export class OrdersComponent {
  @ViewChild('dt1') table!: Table;
  loading: boolean = true;
  errorMessage: string | null = null;
  orderDetails: any;

  constructor(private readonly adminOrderService: AdminOrderService) {
    this.getAllOrderDetails();
  }

  clearFilters() {
    this.table.clear();
  }

  refreshTable() {
    this.getAllOrderDetails();
  }

  getAllOrderDetails() {
    this.loading = true;
    this.errorMessage = null;
    this.adminOrderService.getAllOrderDetails().subscribe(
      (res: any) => {
        console.log(res);
        if (res.statusCode == 200) {
          this.orderDetails = res.data.flatMap((item: any) =>
            item.details.map((detail: any) => ({
              orderId: item?.orderId,
              productId: detail.product.id,
              productName: detail.product.name,
              productSeries: detail.product.productSeries,
              materialType: detail.product.materialType,
              quantity: detail.quantity,
              username: item?.user?.fullName,
              phoneNumber: item?.user?.phoneNumber,
              address: item?.details[0]?.address?.address,
              city: item?.details[0]?.address?.city,
              state: item?.details[0]?.address?.state,
              pincode: item?.user?.pin,
              date: item?.date,
              type: item?.type,
              paymentGroup: item?.paymentGroup,
              cfPaymentId: item?.cfPaymentId,
              deliveryStatus: item?.details[0]?.trackings?.deliveryStatus,
              status: item?.status,
              amount: item?.amount
            }))
          );
        }
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching order details:', error);
        this.errorMessage = 'An unknown error occurred. That\'s all we know.';
        this.loading = false;
      }
    );
  }

  getStatusSeverity(status: string): 'success' | 'info' | 'warning' | 'danger' | undefined {
    if (!status) {
      'warn';
    }
    switch (status) {
      case 'SUCCESS':
        return 'success';
      case 'FAILED':
        return 'danger';
      case 'USER_DROPPED':
        return 'warning';
      default:
        return undefined;
    }
  }

  getStatusLabel(status: string): string {
    if (!status) {
      return 'Unavailable';
    }
    switch (status) {
      case 'SUCCESS':
        return 'Success';
      case 'FAILED':
        return 'Failed';
      case 'USER_DROPPED':
        return 'User Dropped';
      default:
        return status;
    }
  }
}

<section class="container">
    <div class="card flex flex-column align-items-center">
        <p-card [style]="{ width: '100%', maxWidth: '1200px' }">
            <div class="confirm">
                <p style="font-size: 24px" class="font-bold mt-0 mb-0">Orders</p>
                <p *ngIf="errorMessage" class="error-label block">
                    {{ errorMessage }}
                </p>
                <div class="flex justify-content-start align-items-center">
                    <p-button label="Refresh" class="ml-2" (click)="refreshTable()" icon="pi pi-refresh"></p-button>
                    <p-button label="Clear" class="ml-2" (click)="clearFilters()" icon="pi pi-filter-slash"></p-button>
                </div>
            </div>
            <p-card [style]="{ 'margin-top': '1.5rem' }">
                <p-table #dt1 [value]="orderDetails" dataKey="id" [rows]="10" [rowsPerPageOptions]="[10, 25, 50]"
                    [paginator]="true" [sortField]="'date'" [sortOrder]="-1"
                    [globalFilterFields]="['orderId', 'productId', 'productName', 'productSeries', 'matType', 'quantity', 'username', 'address', 'pincode', 'date', 'type', 'status', 'amount']"
                    scrollable="true" scrollHeight="flex" [tableStyle]="{'min-width': '70rem'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="min-width:12rem">
                                <div class="flex align-items-center">
                                    Order ID
                                     <p-columnFilter type="text" field="orderId" display="menu" />
                                </div>
                            </th>
                            <th style="min-width: 10rem">
                                <div class="flex align-items-center">
                                    Product ID
                                    <p-columnFilter type="text" field="productId" display="menu" />
                                </div>
                            </th>
                            <th style="min-width: 12rem">
                                <div class="flex align-items-center">
                                    Product Name
                                    <p-columnFilter type="text" field="productName" display="menu" />
                                </div>
                            </th>
                            <th style="min-width: 12rem">
                                <div class="flex align-items-center">
                                    Product Series
                                    <p-columnFilter type="text" field="productSeries" display="menu" />
                                </div>
                            </th>
                            <th style="min-width: 12rem">
                                <div class="flex align-items-center">
                                    Material Type
                                    <p-columnFilter type="text" field="matType" display="menu" />
                                </div>
                            </th>
                            <th style="min-width: 8rem">
                                <div class="flex align-items-center">
                                    Quantity
                                    <p-columnFilter type="text" field="quantity" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:10rem">
                                <div class="flex align-items-center">
                                    Username
                                    <p-columnFilter type="text" field="username" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:12rem">
                                <div class="flex align-items-center">
                                    Address
                                    <p-columnFilter type="text" field="address" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:12rem">
                                <div class="flex align-items-center">
                                    Contact Number
                                    <p-columnFilter type="text" field="address" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:8rem">
                                <div class="flex align-items-center">
                                    Pincode
                                    <p-columnFilter type="text" field="pincode" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:12rem">
                                <div class="flex align-items-center">
                                    Date
                                    <p-columnFilter type="date" field="date" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:8rem">
                                <div class="flex align-items-center">
                                    Type
                                    <p-columnFilter type="text" field="type" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:12rem">
                                <div class="flex align-items-center">
                                    Payment Mode
                                    <p-columnFilter type="text" field="status" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:12rem">
                                <div class="flex align-items-center">
                                    CF Payment ID
                                    <p-columnFilter type="text" field="cfPaymentId" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:12rem">
                                <div class="flex align-items-center">
                                    Delivery Status
                                    <p-columnFilter type="text" field="status" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:8rem">
                                <div class="flex align-items-center">
                                    Status
                                    <p-columnFilter type="text" field="status" display="menu" />
                                </div>
                            </th>
                            <th style="min-width:8rem">
                                <div class="flex align-items-center">
                                    Amount
                                    <p-columnFilter type="numeric" field="amount" display="menu" currency="INR" />
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-order>
                        <tr>
                            <td class="ellipsis" pTooltip="{{ order.orderId }}" tooltipPosition="top">{{ order.orderId
                                }}</td>
                            <td>{{ order.productId }}</td>
                            <td>{{ order.productName }}</td>
                            <td>{{ order.productSeries }}</td>
                            <td>{{ order.materialType }}</td>
                            <td>{{ order.quantity }}</td>
                            <td>{{ order.username }}</td>
                            @if(order.address) {
                            <td>{{ order.address }},{{ order.city }},{{ order.state }}</td>
                            }
                            @else {
                            <td></td>
                            }
                            <td>{{ order.phoneNumber }}</td>
                            <td>{{ order.pincode }}</td>
                            <td>{{ order.date | date: 'short' }}</td>
                            <td>{{ order.type }}</td>
                            <td>{{ order.paymentGroup }}</td>
                            <td>{{ order.cfPaymentId }}</td>
                            <td>{{ order.deliveryStatus }}</td>
                            <td>
                                <p-tag [value]="getStatusLabel(order.status)"
                                    [severity]="getStatusSeverity(order.status)" />
                            </td>
                            <td>{{ order.amount | currency: 'INR' }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="9">No orders found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-card>
        </p-card>
    </div>
</section>
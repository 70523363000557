import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DialogService } from 'primeng/dynamicdialog';
import { LoginSignupComponent } from '../components/login-signup/login-signup/login-signup.component';

export const adminGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const dialogService = inject(DialogService);

  if (authService.user() == null) {
    const ref = dialogService.open(LoginSignupComponent, {
      header: 'Login/SignUp',
      width: '30%',
      data: { returnUrl: state.url },
    });
    return router.navigate(['/']);
  }

  if (authService.token() == null) {
    return router.navigate(['/']);
  }

  if (authService.jwtTokenPayload().role != 'Admin') {
    return router.navigate(['/']);
  }

  return true;
};

import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { FilterService } from 'primeng/api';
import { CommonModule, DatePipe } from '@angular/common';
import { CardModule } from 'primeng/card';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TagModule } from 'primeng/tag';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { PaginatorModule } from 'primeng/paginator';
import { TransactionService } from '../../../services/admin/transactions/transactions.service';
import { TransactionModel } from '../../../models/admin/transaction.model';
import * as XLSX from 'xlsx';
import { Table } from 'primeng/table';
import { ViewChild } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';

@Component({
  selector: 'app-transactions',
  standalone: true,
  imports: [
    TableModule,
    FormsModule,
    CommonModule,
    CardModule,
    BreadcrumbModule,
    TagModule,
    ButtonModule,
    TooltipModule,
    CalendarModule,
    PaginatorModule,
  ],
  providers: [DatePipe],
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent {
  @ViewChild('dt1') table!: Table;
  transactions: any = [];
  loading: boolean = true;
  errorMessage: string | null = null;
  breadcrumbItems: any[];
  homeIcon: any;
  statusOptions = [
    { label: 'Failed', value: 'Failed' },
    { label: 'Initiated', value: 'Initiated' },
    { label: 'Success', value: 'Success' },
    { label: 'User Dropped', value: 'User Dropped' },
  ];

  constructor(
    private transactionService: TransactionService,
    private filterService: FilterService
  ) {
    this.breadcrumbItems = [
      { label: 'Home', url: '/' },
      { label: 'Admin', url: '/admin' },
      { label: 'Transactions', url: '/admin/transactions' },
    ];
    this.homeIcon = { icon: 'pi pi-home', url: '/' };
    this.getAllTransactions();
  }

  getAllTransactions() {
    this.loading = true;
    this.errorMessage = null;

    this.transactionService.getAllTransactions().subscribe(
      (res: any) => {
        if (res.statusCode === 200) {
          this.transactions = res.data.map((item: any) => ({
            transactionId: item?.transactionsId,
            userId: item?.userId,
            username: item?.user?.fullName,
            email: item?.user?.email,
            phoneNumber: item?.user?.phoneNumber,
            date: item?.date,
            type: item?.type,
            status: item?.status,
            amount: item?.amount,
            orderId: item?.orderId,
          }));
        }
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching transactions:', error);
        this.errorMessage = "An unknown error occurred. That's all we know.";
        this.loading = false;
      }
    );
  }

  clearFilters() {
    this.table.clear();
  }

  refreshTable() {
    this.getAllTransactions();
  }

  getStatusSeverity(
    status: string
  ): 'success' | 'info' | 'warning' | 'danger' | undefined {
    if (!status) {
      ('warn');
    }
    switch (status) {
      case 'SUCCESS':
        return 'success';
      case 'FAILED':
        return 'danger';
      case 'USER_DROPPED':
        return 'warning';
      default:
        return undefined;
    }
  }

  getStatusLabel(status: string): string {
    if (!status) {
      return 'Unavailable';
    }
    switch (status) {
      case 'SUCCESS':
        return 'Success';
      case 'FAILED':
        return 'Failed';
      case 'USER_DROPPED':
        return 'User Dropped';
      default:
        return status;
    }
  }

  exportToExcel() {
    const worksheetData = this.transactions.map((transaction: any) => ({
      'Transaction ID': transaction.transactionId,
      'Order ID': transaction.orderId,
      'Username': transaction.username,
      'Email': transaction.email,
      'Contact Number': transaction.phoneNumber,
      'Date': transaction.date,
      'Type': transaction.type,
      'Status': this.getStatusLabel(transaction.status),
      'Amount': transaction.amount
    }));

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(worksheetData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Transactions');
    XLSX.writeFile(wb, 'transactions.xlsx');
  }
}

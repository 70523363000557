<div class="chat-container" [ngClass]="{ 'show-chat': isOpen }">
  <div class="chat-header pl-3">
    <div class="flex align-items-center">
      <img src="assets/digigold.ico" class="replyBot mr-3" alt="Chatbot" />
      <h3>Help Center</h3>
    </div>
    <button class="close-chat pr-3" (click)="toggleChat()">×</button>
  </div>

  <div class="chat-messages">
    <!-- Welcome messages -->
    <div class="flex align-items-center">
      <img src="assets/chatbot.png" class="replyBot mr-3" alt="Chatbot" />
      <div class="message bot">
        <p class="my-0">Welcome to SMC Coins!</p>
        <p class="my-0">Ask me anything, I am here to assist you 😄</p>
      </div>
    </div>

    <div *ngFor="let message of messages; let i = index" class="message-container">
      <div class="flex" [ngClass]="{ 'user-message': message.type === 'user', 'bot-message': message.type === 'bot' }">
        <img *ngIf="message.type === 'bot'" src="assets/chatbot.png" class="replyBot mr-3" alt="Chatbot" />
        <div class="message" [ngClass]="message.type">
          {{ message.text }}
          <div *ngIf="message.suggestionList" class="suggestions">
            <span
              *ngFor="let suggestion of message.suggestionList; let j = index"
              (click)="selectSuggestion(suggestion)"
              class="suggestion-item my-1"
            >
              {{ j + 1 }}. {{ suggestion.question }}
            </span>
          </div>
          <div *ngIf="message.buttons && !message.hasResponse">
            <button
              *ngFor="let button of message.buttons"
              (click)="handleButtonClick(button.value, i)"
              class="chat-btn"
            >
              {{ button.label }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Ticket form (shown conditionally) -->
    <div *ngIf="showTicketForm" class="mt-1 ticket-form">
      <form [formGroup]="jiraForm" (ngSubmit)="submitTicket()">
        <div class="flex flex-column">
          <div class="mb-0 field col-12 mb-0">
            <p class="block font-bold">
              Subject<span class="text-pink-500">*</span>
            </p>
            <input
              pInputText
              type="text"
              class="w-full p-inputtext-sm"
              formControlName="subject"
              placeholder="Enter Ticket Subject"
            />
            <span
              class="p-error block mt-1"
              *ngIf="jiraFormControls?.['subject']?.touched && jiraFormControls?.['subject']?.errors?.['required']"
              >Subject is required</span
            >
          </div>
          <div class="field col-12 mb-2">
            <p class="block font-bold">
              Description<span class="text-pink-500">*</span>
            </p>
            <textarea [rows]="5" [cols]="32" pInputTextarea formControlName="description" autoResize="autoResize"></textarea>
            <span
              class="p-error block mt-1"
              *ngIf="jiraFormControls?.['description']?.touched && jiraFormControls?.['description']?.errors?.['required']"
              >Description is required</span
            >
          </div>
          
        <p-button
        type="submit"
        severity="success"
        label="Submit"
        class="mt-1 ml-1"
        [disabled]="!jiraForm.valid"
      ></p-button>
        </div>
      </form>
    </div>
  </div>

  <div class="chat-input" *ngIf="!showTicketForm">
    <input
              pInputText
              type="text"
              class="w-full p-inputtext-sm"
      [(ngModel)]="userMessage"
              placeholder="Type your message..."
            />
    <p-button class="mt-1" severity="info" label="Submit" (click)="sendMessage()" [disabled]="userMessage.trim().length == 0"></p-button>
  </div>
</div>

<!-- Floating chat icon -->
<div class="chat-icon" 
     (click)="toggleChat()" 
     [ngClass]="{ 'visible': !isOpen, 'hide': isOpen, 'no-animation': !isAnimating }">
  <img src="assets/chatbot.png" alt="Chatbot" />
</div>


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private readonly http: HttpClient,private readonly encryption:EncryptionService) {}

  createJiraTicket(subject: string, description: string): Observable<any> {
    const issuePayload = {
      fields: {
        project: {
          key: 'SDW'
        },
        summary: subject,
        description: description,
        issuetype: {
          name: 'Task'
        },
        assignee: { id: '712020:a2bce043-ba51-428c-8d38-e42445c64dd6' }
      }
    };


    return this.http.post(`${environment.digigoldbe}/common/create-ticket`, this.encryption.encrypt(issuePayload));

  }
}

<header class="flex gap-2 align-items-center p-4 px-8">
  <p-button
    icon="pi pi-bars"
    severity="secondary"
    (onClick)="sidebarVisible = true"
  ></p-button>
  <h1 class="m-0">SMC Coins</h1>
  <div class="flex-auto"></div>
  <p-button label="Home" text [routerLink]="['/user/dashboard']"></p-button>
  <p-button label="Sign Out" text (onClick)="signOutUser()"></p-button>
</header>

<p-sidebar [(visible)]="sidebarVisible">
  <!-- Sidebar Header -->
  <ng-template pTemplate="header">
    <h2 class="m-0">Admin Panel</h2>
  </ng-template>
  <!-- Sidebar Content -->
  <div>
    <p-menu [model]="items" class="w-full" styleClass="w-full text-lg"></p-menu>
  </div>
</p-sidebar>

<article>
  <ng-content></ng-content>
  <router-outlet />
</article>

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DialogService } from 'primeng/dynamicdialog';
import { of, switchMap } from 'rxjs';
import { LoginSignupComponent } from '../components/login-signup/login-signup/login-signup.component';

export const authGuard: CanActivateFn = (route, state) => {
  console.log('return url guard', state.url);

  const authService = inject(AuthService);
  const router = inject(Router);
  const dialogService = inject(DialogService);

  if (authService.user() == null) {
    const ref = dialogService.open(LoginSignupComponent, {
      header: 'Login/SignUp',
      width: '30%',
      data: { returnUrl: state.url },
    });
    console.log('return url after guard', state.url);
    return false;
  }

  return true;
};

import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorInterceptorFn } from './services/http-interceptor';
import { MessageService } from 'primeng/api';
import { EncryptionService } from './services/encryption.service';
import { CommonService } from './services/common/common.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideRouter(routes),
    provideClientHydration(),
    provideHttpClient(withInterceptors([ErrorInterceptorFn])),
    DialogService,
    MessageService,
    EncryptionService,
    CommonService,
  ],
};

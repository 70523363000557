@for (item of items; track $index) {
<div class="flex justify-content-between align-items-center w-full">
  <a
    class="block text-lg py-2 font-medium no-underline text-color hover:text-primary"
    [routerLink]="['/', 'product', item.product?.id]"
  >
    {{ item.product?.name }}
  </a>
  {{ item.count }}
</div>
}
<!-- {{ items.length }}
<p-dataView [value]="items">
  <ng-template pTemplate="list" let-item>
    <div class="block">
      <a
        class="block text-lg py-2 font-medium no-underline text-color hover:text-primary"
        [routerLink]="[item.product?.id]"
      >
        {{ item.product?.name }}
      </a>
      {{ item.count }}
    </div>
  </ng-template>
</p-dataView> -->

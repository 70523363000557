import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { CreateProductModel } from '../../../models/admin/product.model';
import { switchMap } from 'rxjs/operators';
import { tap, map } from 'rxjs/operators';
import { ProductDetailModel } from '../../../models/admin/product-detail.model';

@Injectable({
  providedIn: 'root',
})
export class AdminProductService {
  private apiUrl = environment.digigoldbe;
  constructor(private http: HttpClient) {}

  getAllProducts() {
    const url = `${this.apiUrl}/products`;
    return this.http.get(url);
  }
  addProduct(product: any) {
    const url = `${this.apiUrl}/products`;
    return this.http.post(url, product);
  }

  updateProductById(productId: number, product: any) {
    const url = `${this.apiUrl}/products/${productId}`;
    return this.http.put<CreateProductModel>(url, product);
  }

  deleteProductById(productId: number) {
    const url = `${this.apiUrl}/products/${productId}`;
    return this.http.delete(url);
  }

  uploadProductImages(
    productId: number,
    name: string,
    files: File[]
  ): Observable<any> {
    const url = `${this.apiUrl}/gcp-controls/upload-files`;
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('folder', `ProductImages/${new Date().getTime()}_`);

    return this.http.post(url, formData).pipe(
      switchMap((response: any) => {
        // Collect all observables in an array
        const allImages: Observable<any>[] = [];

        const secondApiUrl = `${this.apiUrl}/products/image`;
        response.data.data.forEach((img: any) => {
          const body = {
            productId: productId,
            name: name,
            url: img,
          };

          allImages.push(this.http.post(secondApiUrl, body));
        });

        return forkJoin(allImages);
      }),
      tap({
        next: (res) => {
          console.log('Image information saved successfully:', res);
        },
        error: (error) => {
          console.error('Error saving image information:', error);
        },
      })
    );
  }

  getProductDetailById() {
    const url = `${this.apiUrl}/product-details`;
    return this.http.get(url);
  }

  deleteProductImageById(imageId: number) {
    const url = `${this.apiUrl}/products/image?id=${imageId}`;
    return this.http.delete(url);
  }

  // Product details
  getProductDetails() {
    const url = `${this.apiUrl}/product-details`;
    return this.http.get(url);
  }

  createDetail(detail: any) {
    const url = `${this.apiUrl}/product-details`;
    return this.http.post(url, detail);
  }

  updateDetail(id: number, detail: any) {
    const url = `${this.apiUrl}/product-details/${id}`;
    return this.http.patch(url, detail);
  }

  deleteProductDetailById(id: number) {
    const url = `${this.apiUrl}/product-details/${id}`;
    return this.http.delete(url);
  }

  // Dropdown functions
  getMaterialTypeOptions() {
    const url = `${this.apiUrl}/config-values/master/MetalType`;
    return this.http.get(url);
  }

  getProductShapeOptions() {
    const url = `${this.apiUrl}/config-values/master/ProductShape`;
    return this.http.get(url);
  }

  getProductSeriesOptions() {
    const url = `${this.apiUrl}/config-values/master/ProductSeries`;
    return this.http.get(url);
  }
}

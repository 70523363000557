import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MastersService } from '../../services/masters.service';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-chat-bot',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextareaModule,
    InputTextModule,
  ],
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss'],
})
export class ChatBotComponent {
  isOpen = false;
  userMessage = '';
  messages: {
    text: string;
    type: 'user' | 'bot';
    suggestionList?: { question: string; answer: string }[];
    buttons?: { label: string; value: string }[];
    hasResponse?: boolean;
  }[] = [];
  faqs: { question: string; answer: string,
    tags:string}[] = [];
  showTicketForm = false;
  ticket = { subject: '', description: '' };
  jiraForm!: FormGroup;
  isAnimating = true; 

  constructor(
    private readonly master: MastersService,
    private readonly fb: FormBuilder,
    private readonly chatService: ChatService
  ) {}

  ngOnInit() {
    // Fetch all FAQs
    this.master.getAllFaqs().subscribe((data) => {
      if (data.faq) this.faqs.push(...data.faq);
      if (data.faqSip) this.faqs.push(...data.faqSip);
  
      this.faqs.forEach(faq => {
        if (faq.tags) {
          faq.tags = JSON.parse(faq.tags);
        }
      });
      console.log('faq',this.faqs)
    });

    this.jiraForm = this.fb.group({
      subject: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  toggleChat() {
    this.isOpen = !this.isOpen;
    this.isAnimating = false;
  }

  get jiraFormControls() {
    return this.jiraForm.controls;
  }

  sendMessage() {
    if (this.userMessage.trim()) {
      this.messages.push({ text: this.userMessage, type: 'user' });
      const userMessage = this.userMessage;
      this.userMessage = '';

      const matchedSuggestions = this.getSuggestions(userMessage);

      if (matchedSuggestions.length > 0) {
        this.messages.push({
          text: `Here are some suggestions:`,
          type: 'bot',
          suggestionList: matchedSuggestions,
        });
        this.addResolutionPrompt();
      } else {
        this.messages.push({
          text: "I'm sorry, I don't understand that. Please check all FAQs:",
          type: 'bot',
          suggestionList: this.faqs,
        });

        this.messages.push({
          text: 'Do you have other queries and want to raise a ticket?',
          type: 'bot',
          buttons: [
            { label: 'Yes', value: 'raise-ticket' },
            { label: 'No', value: 'no-ticket' },
          ],
          hasResponse: false,
        });
      }
    }
  }

  addResolutionPrompt() {
    this.messages.push({
      text: 'I hope it resolved your query.',
      type: 'bot',
      buttons: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
      hasResponse: false,
    });
  }

  getSuggestions(userInput: string) {
    const lowerCasedInput = userInput.toLowerCase();
    return this.faqs.filter((faq:any) => 
      faq?.question?.toLowerCase().includes(lowerCasedInput) || 
      faq?.answer?.toLowerCase().includes(lowerCasedInput) || 
      faq?.tags?.some((tag:any) => tag.includes(lowerCasedInput) || lowerCasedInput.includes(tag))
    );
  }
  

  selectSuggestion(suggestion: { question: string; answer: string }) {
    this.messages.push({ text: suggestion.question, type: 'user' });
    this.messages.push({ text: suggestion.answer, type: 'bot' });
  
    const lastPromptIndex = this.messages.findIndex(
      message => message.buttons && !message.hasResponse
    );
    
    if (lastPromptIndex !== -1) {
      this.messages[lastPromptIndex].hasResponse = true;
    }
  
    this.addResolutionPrompt();
  }
  

  handleButtonClick(buttonValue: string, messageIndex: number) {
    this.messages[messageIndex].hasResponse = true;
    console.log(buttonValue);

    if (buttonValue === 'yes') {
      this.messages.push({
        text: 'I am so happy to help you! 😊',
        type: 'bot',
      });
    } else if (buttonValue === 'no' || buttonValue === 'no-ticket') {
      // Check if this "No" is in response to the "raise-ticket" question
      if (buttonValue == 'no-ticket') {
        // Custom "No" response for the ticket raising scenario
        this.messages.push({
          text: 'Thank you for reaching out! 😊 You can come back anytime if you have more questions. Have a wonderful day!',
          type: 'bot',
        });
      } else {
        // Default "No" behavior
        this.messages.push({
          text: 'Please check all FAQs:',
          type: 'bot',
          suggestionList: this.faqs,
        });

        this.messages.push({
          text: 'Do you have other queries and want to raise a ticket?',
          type: 'bot',
          buttons: [
            { label: 'Yes', value: 'raise-ticket' },
            { label: 'No', value: 'no-ticket' },
          ],
          hasResponse: false,
        });
      }
    }

    if (buttonValue === 'raise-ticket') {
      this.showTicketForm = true;
      this.messages.push({
        text: 'Please fill out the form below to raise a ticket.',
        type: 'bot',
      });
    }
  }

  // submitTicket() {
  //   const { subject, description } = this.jiraForm.value;

  //   if (subject && description) {
  //     this.submitTicketToServer(subject, description);
  //     this.messages.push({
  //       text: 'Your ticket has been raised! The team will connect with you within 24 hours.',
  //       type: 'bot',
  //     });
  //     this.showTicketForm = false;
  //     this.jiraForm.reset();
  //   } else {
  //     this.messages.push({
  //       text: 'Please fill out all fields before submitting.',
  //       type: 'bot',
  //     });
  //   }
  // }

  submitTicket() {
    const { subject, description } = this.jiraForm.value;

    if (subject && description) {
      // Call the service to create a Jira ticket
      this.chatService.createJiraTicket(subject, description).subscribe({
        next: (response) => {
          console.log('Ticket created successfully:', response);
          this.messages.push({
            text: `Your ticket #${response.data.id} has been raised! The team will connect with you within 48 hours.`,
            type: 'bot',
          });
        },
        error: (error) => {
          console.error('Error creating ticket:', error);
          this.messages.push({
            text: 'There was an error raising the ticket. Please try again later.',
            type: 'bot',
          });
        },
      });

      // Hide the form after submission
      this.showTicketForm = false;
      this.jiraForm.reset();
    }
  }

}

import { Component } from '@angular/core';
import { CartService } from '../../services/product/cart.service';
import { ProductService } from '../../services/product/product.service';
import { RouterModule } from '@angular/router';
import { DataViewModule } from 'primeng/dataview';

@Component({
  selector: 'app-cart-view',
  standalone: true,
  imports: [RouterModule, DataViewModule],
  templateUrl: './cart-view.component.html',
  styleUrl: './cart-view.component.scss',
})
export class CartViewComponent {
  get items() {
    return this.cartService.cart
      .filter((ci) => ci.count > 0)
      .map((cartItem) => {
        var product = this.productService
          .products()
          .find((p) => p.id == cartItem.id);
        var count = cartItem.count;
        return { product, count };
      });
  }

  constructor(
    private readonly cartService: CartService,
    private readonly productService: ProductService
  ) {
    if (productService.products().length == 0) {
      productService.getAllProducts().subscribe();
    }
  }
}

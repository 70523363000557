import { MenuItem } from 'primeng/api';

export const items: MenuItem[] = [
  {
    label: 'Transactions',
    routerLink: '/admin/transactions',
  },
  {
    label: 'Products',
    routerLink: '/admin/products',
  },
  {
    label: 'Orders',
    routerLink: '/admin/orders',
  }
];
